import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {Button, Col, Row} from "react-bootstrap";
import ProductPictures from "../components/ProductPictures";
import Breadcrumbs from "./Breadcrumbs";
import {ST} from "../global/SystemText";
import ProductAccessory from "./ProductAccessory";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import unknown from "../images/unknown.jpg";
import ProductWarehouse from "./ProductWarehouse";
import Properties from "./Properties";
import {Link} from "react-router-dom";

export default class Product2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loaded: false,
            loaded2: false,
            details: {},
            tabIndex: 0,
            activeType: 0,
            productId: this.props.match.params.id, amount: 1,
            hideAccessory: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.id !== state.productId) {
            state.productId = props.match.params.id;
            state.loaded = false;
            state.loaded2 = false;
            state.tabIndex = 1;
            state.activeType = 0;
            state.hideAccessory = false;
            return state;
        }
        return null;
    }

    componentDidMount() {
        this.loadProduct();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loaded) {
            this.loadProduct();
        }
    }

    changeInput = (event) => {
        this.setState({amount: event.target.value});
    };
    loadProduct = () => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idProduct: String(this.state.productId),
                    idCatalogue: "0",
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    action: "GET_PORTAL_PRODUCT"
                }
            })
            .then((response) => {
                this.setState({data: response.data, loaded: true});
                axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                    params: {
                        idMandant: Session.idMandant,
                        idProduct: response.data.idParent,
                        action: "GET_PORTAL_PRODUCT_DETAIL"
                    }
                }).then((response2) => {

                    this.setState({
                        details: response2.data,
                        loaded2: true
                    });
                }).catch((error) => {
                    PT.handleError(error, this.props.history);
                })

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    };

    selectTab = (tabIndex) => {

        this.setState({tabIndex});
    };

    hideAccessory() {
        let activeTab = 0;
        if (this.state.data.htmlDescription !== "") {
            activeTab = 0;
        } else if (this.state.details.videos.length > 0) {
            activeTab = 2;
        } else if (this.state.details.documents.length > 0) {
            activeTab = 3;
        } else {
            activeTab = -1;
        }

        this.setState({
            hideAccessory: true,
            tabIndex: activeTab
        })
    }

    getTabContent = () => {
        let {data, tabIndex, details} = this.state;
        switch (tabIndex) {
            default:
            case 0:
                return (
                    <Row>
                        <Col md={12}><div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: data.htmlDescription}} /></Col>
                        <ProductAccessory style={{display: "none"}} id={data.idParent} isEmpty={this.hideAccessory.bind(this)}/>

                    </Row>
                );
            case 1:
                return (
                    <ProductAccessory id={data.idParent} isEmpty={this.hideAccessory.bind(this)}/>
                );

            case 2:
                return (
                    <Row>
                        {details.videos.map((element, index) => (
                            <Col md={4} key={index}>
                                <iframe title={"video" + index} width="100%" height="300px" src={element.replace("watch?v=", "embed/")} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                            </Col>
                        ))}
                    </Row>
                );

            case 3:
                return (
                    <div>
                        {details.documents.map((element, index) => (
                            <div key={index}>
                                <a target="_blank" rel="noopener noreferrer" href={Session.getItem("endpoint") + element.url}>
                                    <Row className="mt-10">
                                        <Col md={12} className="">
                                            <i className={"oicon24 oicon-" + element.ext}/> {element.name}
                                        </Col>

                                    </Row>
                                </a>
                            </div>
                        ))}
                    </div>
                );
        }
    };
    selectType = (index) => {
        this.setState({activeType: index});
    };


    render() {
        let {loaded, data, tabIndex, details} = this.state;
        if (loaded) {
            return (<div>
                <div className="">
                    <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                        <Breadcrumbs type={"product"} catName={data.catalogue.name} id={data.catalogue.langParent} active={data.name}/>
                        <div className="panel-body">
                            <Row className="row">
                                <Col md={4}>
                                    <div className={"picture-wrapper"}>
                                    {data.types.length > 0 ?
                                        <ProductPictures id={this.state.activeType} pictures={data.types[this.state.activeType].pictures}/>
                                        :
                                        <ProductPictures id={-1} pictures={data.pictures}/>
                                    }
                                        {Boolean(window.portalSettings.show_prices) && data.prices.customerPrices.discount > 0 ?
                                            <div className="discount"><span style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>{data.prices.customerPrices.discount}%</span></div>
                                            : null}
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col md={12}>
                                            <h1 className="no-margin-top" dangerouslySetInnerHTML={{__html: data.name}}/>
                                            {data.types.length === 0 ?
                                                <div className="product-no-types">
                                                    <Row>
                                                        <Col md={5}>
                                                            {data.model !== "" ?
                                                                <div>
                                                                    {ST.get("product_model", "product")} : <b>{data.model}</b>
                                                                </div>
                                                                : null}
                                                            {data.orderOur !== "" ?
                                                                <div>
                                                                    {ST.get("product_order_our", "product")} : <b>{data.orderOur}</b>
                                                                </div>
                                                                : null}
                                                            {data.ean !== "" ?
                                                                <div>
                                                                    {ST.get("product_ean", "product")} : <b>{data.ean}</b>
                                                                </div>
                                                                : null}
                                                            {parseFloat(data.weight) > 0 ?
                                                                <div>
                                                                    {ST.get("product_weight", "product")} : <b>{data.weight} kg</b>
                                                                </div>
                                                                : null}
                                                            <div>
                                                                {data.replacements.length === 0 ?
                                                                    <ProductWarehouse data={data.stock} id={data.idParent} idType={0}/>
                                                                    : null}
                                                            </div>
                                                            <div>
                                                                {data.tags.map((element, index) => (
                                                                    <span className={"badge obadge"} style={{background:element.color}}>{element.name}</span>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            {data.ean !== "" ?
                                                                <img alt="barcode" className="img-responsive " src={Session.getItem("endpoint") + "BarcodeServlet?id=" + data.idParent}/>
                                                                : null}
                                                        </Col>
                                                        <Col md={3} className="text-right">
                                                            {data.idProducer > 0 ?
                                                                <Link to={Session.getItem("basename") + "/producer/"+ data.idProducer} >
                                                                <img className="img-responsive" src={Session.getItem("endpoint") + "ProducerLogoServlet?id=" + data.idProducer} alt="producer_logo"/>
                                                                </Link>
                                                                : null}

                                                        </Col>
                                                    </Row>
                                                    {data.replacements.length === 0 ?
                                                        data.prices.customerPrices.price > 0 ?
                                                            <Row>
                                                                {Boolean(window.portalSettings.show_prices) ? (
                                                                    <Col md={6}>
                                                                        <div className="price">
                                                                            {data.prices.showRecPrice ?
                                                                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                    <div>{ST.get("price_category_selling_price", "product")} : <s><b>{data.prices.recsellPrices.formatedPrice.replace(/eur/ig, "€")} {ST.get("global_excl_vat", "global")}</b></s></div>
                                                                                    :
                                                                                    <div>{ST.get("price_category_selling_price", "product")} : <s><b>{data.prices.recsellPrices.formatedPriceVat.replace(/eur/ig, "€")} {ST.get("global_incl_vat", "global")}</b></s></div>
                                                                                : null
                                                                            }
                                                                            {data.prices.showMainPrice ?
                                                                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                    <div>{ST.get("base_price", "global")} : <s><b>{data.prices.mainPrices.formatedPrice.replace(/eur/ig, "€")} {ST.get("global_excl_vat", "global")}</b></s></div>
                                                                                    :
                                                                                    <div>{ST.get("base_price", "global")} : <s><b>{data.prices.mainPrices.formatedPriceVat.replace(/eur/ig, "€")} {ST.get("global_incl_vat", "global")}</b></s></div>
                                                                                : null
                                                                            }
                                                                            {data.prices.customerPrices.discount>0 ?
                                                                                <div>{ST.get("goods_group_discount", "global")} : <b>{data.prices.customerPrices.discount}%</b></div>
                                                                                : null
                                                                            }
                                                                            {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                <div>
                                                                                    <span className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</span> {ST.get("global_excl_vat", "global")} {data.unit?<>/ {data.unit}</>:null}
                                                                                    <div>{data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")} {ST.get("global_incl_vat", "global")}</div>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <span className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</span> {ST.get("global_incl_vat", "global")} {data.unit?<>/ {data.unit}</>:null}
                                                                                    <div>{data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")} {ST.get("global_excl_vat", "global")}</div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                ) : <Col md={6}/> }
                                                                <Col md={6} className="pt-20">
                                                                    <div className="form-group form-group-material">
                                                                        {!data.backorderLock ?
                                                                        <div className="input-group">
                                                                            <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                                                                            <div className="input-group-btn no-padding-left">
                                                                                <Button onClick={async (e) => await PT.addToBasket(this.state.amount, data.idParent, 0, data,null,true,e)} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                                                                            </div>
                                                                        </div>: <div className={"unavailable-text"}>{ST.get("unavailable", "portal")}</div>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="price">
                                                                        <div className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("on_demand", "global")}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        :
                                                        <span>
                                                            <Col md={12}>
                                                                <div className="price">
                                                                    <div className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("not_selling", "portal")}</div>
                                                                </div>
                                                            </Col>
                                                            {ST.get("replacements", "product")} :&nbsp;
                                                                <strong>
                                                                    {data.replacements.map((element, index) => (
                                                                        <Link key={index} to={Session.getItem("basename") + "/product/" + element.id}>
                                                                            {index > 0 ? ", " : ""}{element.name} {element.model}
                                                                        </Link>
                                                                    ))}
                                                                </strong>
                                                        </span>
                                                    }
                                                </div>
                                                :
                                                <div className={"product-with-types"}>
                                                    <Row>
                                                        <Col md={5}>
                                                            {data.types[this.state.activeType].model !== "" ?
                                                                <div>
                                                                    {ST.get("product_model", "product")} : <b>{data.types[this.state.activeType].model}</b>
                                                                </div>
                                                                : null}
                                                            {data.types[this.state.activeType].orderOur !== "" ?
                                                                <div>
                                                                    {ST.get("product_order_our", "product")} : <b>{data.types[this.state.activeType].orderOur}</b>
                                                                </div>
                                                                : null}
                                                            {data.types[this.state.activeType].ean !== "" ?
                                                                <div>
                                                                    {ST.get("product_ean", "product")} : <b>{data.types[this.state.activeType].ean}</b>
                                                                </div>
                                                                : null}
                                                            {data.types[this.state.activeType].weight > 0 ?
                                                                <div>
                                                                    {ST.get("product_weight", "product")} : <b>{data.types[this.state.activeType].weight}&nbsp;kg</b>
                                                                </div>
                                                                : null}
                                                            <div>
                                                                {data.replacements.length === 0 ?
                                                                    <ProductWarehouse id={data.idParent} idType={data.types[this.state.activeType].id} data={data.types[this.state.activeType].stock}/>:null
                                                                }
                                                            </div>
                                                            <div>
                                                                {data.types[this.state.activeType].tags.map((element, index) => (
                                                                    <span className={"badge obadge"} style={{background:element.color}}>{element.name}</span>
                                                                ))}
                                                            </div>

                                                        </Col>
                                                        <Col md={4}>
                                                            {data.types[this.state.activeType].ean !== "" ?
                                                                <img className="img-responsive " alt="barcode" src={Session.getItem("endpoint") + "BarcodeServlet?product_type_id=" + data.types[this.state.activeType].id}/>
                                                                : null}
                                                        </Col>
                                                        <Col md={3} className="text-right">
                                                            {data.idProducer > 0 ?
                                                                <img className="img-responsive" src={Session.getItem("endpoint") + "ProducerLogoServlet?id=" + data.idProducer} alt="producer_logo"/>
                                                                : null}

                                                        </Col>
                                                    </Row>
                                                    {data.replacements.length === 0 ?
                                                    data.types[this.state.activeType].prices.customerPrices.price > 0 ?
                                                        <Row>
                                                            {Boolean(window.portalSettings.show_prices) ? (
                                                                <Col md={6}>
                                                                    <div className="price">
                                                                        {data.types[this.state.activeType].prices.showRecPrice ?
                                                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                <div>{ST.get("price_category_selling_price", "product")} : <b><s>{data.types[this.state.activeType].prices.recsellPrices.formatedPrice}</s></b></div>
                                                                                :
                                                                                <div>{ST.get("price_category_selling_price", "product")} : <b><s>{data.types[this.state.activeType].prices.recsellPrices.formatedPrice}</s></b></div>
                                                                            : null
                                                                        }
                                                                        {data.types[this.state.activeType].prices.showMainPrice ?
                                                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                <div>{ST.get("base_price", "global")} : <b><s>{data.types[this.state.activeType].prices.mainPrices.formatedPrice}</s></b></div>
                                                                                :
                                                                                <div>{ST.get("base_price", "global")} : <b><s>{data.types[this.state.activeType].prices.mainPrices.formatedPrice}</s></b></div>
                                                                            : null
                                                                        }
                                                                        {data.types[this.state.activeType].prices.customerPrices.discount >0 ?
                                                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                                <div>{ST.get("goods_group_discount", "global")} : <b>{data.types[this.state.activeType].prices.customerPrices.discount}%</b></div>
                                                                                :
                                                                                <div>{ST.get("goods_group_discount", "global")} : <b>{data.types[this.state.activeType].prices.customerPrices.discount}%</b></div>
                                                                            : null
                                                                        }
                                                                        {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                            <div>
                                                                                <span className="main-price"
                                                                                      style={{color: window.portalSettings.portal_pcolor}}>{data.types[this.state.activeType].prices.customerPrices.formatedPrice}</span> {ST.get("global_excl_vat", "global")} {data.unit?<>/ {data.unit}</>:null}
                                                                                <div>{data.types[this.state.activeType].prices.customerPrices.formatedPriceVat} {ST.get("global_incl_vat", "global")}</div>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <span className="main-price"
                                                                                      style={{color: window.portalSettings.portal_pcolor}}>{data.types[this.state.activeType].prices.customerPrices.formatedPriceVat}</span> {ST.get("global_incl_vat", "global")} {data.unit?<>/ {data.unit}</>:null}
                                                                                <div>{data.types[this.state.activeType].prices.customerPrices.formatedPrice} {ST.get("global_excl_vat", "global")}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            ) : <Col md={6}/> }
                                                            <Col md={6} className="pt-20">
                                                                <div className="form-group form-group-material">
                                                                    {!data.types[this.state.activeType].backorderLock ?
                                                                    <div className="input-group">
                                                                        <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                                                                        <div className="input-group-btn no-padding-left">
                                                                            <Button onClick={async (e) => await PT.addToBasket(this.state.amount, data.idParent, data.types[this.state.activeType].id, data,data.types[this.state.activeType],true,e)} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                                                                        </div>
                                                                    </div>:<div className={"unavailable-text"}>{ST.get("unavailable", "portal")}</div>}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="price">
                                                                    <div className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("on_demand", "global")}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <span>
                                                            <Col md={12}>
                                                                <div className="price">
                                                                    <div className="main-price" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("not_selling", "portal")}</div>
                                                                </div>
                                                            </Col>
                                                            {ST.get("replacements", "product")} :&nbsp;
                                                            <strong>
                                                                    {data.replacements.map((element, index) => (
                                                                        <Link key={index} to={Session.getItem("basename") + "/product/" + element.id}>
                                                                            {index > 0 ? ", " : ""}{element.name} {element.model}
                                                                        </Link>
                                                                    ))}
                                                                </strong>
                                                        </span>
                                                    }

                                                    <Row className={"equal-heights"} style={{paddingTop: "20px"}}>
                                                        {data.types.map((element, index) => {
                                                            return (<TypeTile idProduct={data.idParent} noPrice={!Boolean(window.portalSettings.show_prices)} {...element} active={this.state.activeType === index} key={"type" + index} onClick={this.selectType.bind(this, index)}/>);
                                                        })}
                                                    </Row>
                                                </div>}
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={12} className="mt-20" dangerouslySetInnerHTML={{__html: data.description.replace("\n",<br/>)}}/>

                                        <Col md={6} className="mt-20"><Properties idProduct={data.id} idType={data.types.length > 0 ? data.types[this.state.activeType].id : 0}/></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                {this.state.loaded2 && this.state.tabIndex >= 0 ?
                                    <Col md={12}>

                                        <ul className="nav nav-tabs product-tabs">
                                            {data.htmlDescription !== "" ?
                                                <li className={tabIndex === 0 ? "active" : ""}><a onClick={this.selectTab.bind(this, 0)}>{ST.get("description", "product")}</a></li>
                                                : null}
                                            {!this.state.hideAccessory ?
                                                <li className={tabIndex === 1 ? "active" : ""}><a onClick={this.selectTab.bind(this, 1)}>{ST.get("accessory", "product")}</a></li>
                                                : null}
                                            {details.videos.length > 0 ? <li className={tabIndex === 2 ? "active" : ""}><a onClick={this.selectTab.bind(this, 2)}>{ST.get("videos", "global")}</a></li> : null}
                                            {details.documents.length > 0 ? <li className={tabIndex === 3 ? "active" : ""}><a onClick={this.selectTab.bind(this, 3)}>{ST.get("for_download", "global")}</a></li> : null}

                                        </ul>

                                        <div className={"tab-pane active"}>
                                            {this.getTabContent()}
                                        </div>

                                    </Col>
                                    : null}
                            </Row>


                        </div>

                    </div>

                </div>

            </div>);
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}

function TypeTile(props) {
    //console.log(props);
    return (
        <Col md={3} lg={4} className="text-center type-tile">
            <Row onClick={props.onClick} className={props.active ? "active" : ""} style={props.active ? {borderColor: window.portalSettings.portal_pcolor} : {}}>
                <Col md={5}>
                    <img src={props.pictures !== undefined && props.pictures.length > 0 ? Session.getItem("endpoint") + props.pictures[0] : unknown} style={{maxHeight: "80px"}} className="img-responsive"/>
                </Col>
                <Col md={7}>
                    <strong>{props.name}</strong>
                </Col>
                {!props.noPrice?
                <Col md={7} className="text-bold text-size-large" style={{color: window.portalSettings.portal_pcolor}}>
                    {props.prices.customerPrices.price>0?
                    props.prices.customerPrices.formatedPrice: ST.get("on_demand","global")}
                </Col>:null}
                <div style={{position:"absolute",bottom: "3px",right: "23px"}}>
                    <ProductWarehouse noLabel={true} id={props.idProduct} data={props.stock} idType={props.id}/>
                </div>
            </Row>
        </Col>
    );
}