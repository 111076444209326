import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Link} from "react-router-dom";
import {ST} from "../global/SystemText";
import ProductWarehouse from "../B2B/ProductWarehouse";

export default class ProductQORow extends Component {

    constructor(props) {
        super(props);
            let typeIndex=0;
            props.data.types.forEach((element,index)=>{
                if(element.id===Number(props.selectedType)){
                    typeIndex=index;
                }
            });


        this.state = {
            amount: this.props.amount,
            selectedType:typeIndex
        }
    };

    changeInput = (event) => {
        this.setState({amount: event.target.value});
        if(this.props.onChangeAmount!==undefined){
            this.props.onChangeAmount(event.target.value)
        }
    };

    selectType(event){
        this.setState({selectedType:event.target.value});
        if(this.props.onChangeType!==undefined){
            this.props.onChangeType(this.props.data.types[event.target.value].id)
        }

    }



    render() {
        try {
            let {
                name, id, idParent, orderOur,
                model, price, formatedPrice, priceVat,
                formatedPriceVat, vatKoef, weight,
                catalogue, pictures, isLocked, backorderLock,
                types, available, unit, recPrice,
                formatedRecPrice, showRecPrice, replacements
            } = this.props.data;


            let typeSelector =
                <select className="type-select" value={this.state.selectedType} onChange={this.selectType.bind(this)}>
                    {types.map((element, index) => {
                        return (<option key={"option" + index} value={index}>{element.name} | {element.orderOur}</option>);
                    })
                    }
                </select>;

            let prices = types !== undefined && types.length > 0 ?
                <span>
                            {types[this.state.selectedType].prices.showRecPrice ?
                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                    <div className="text-size-mini text-muted no-wrap"><s>{types[this.state.selectedType].prices.recsellPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                    :
                                    <div className="text-size-mini text-muted no-wrap"><s>{types[this.state.selectedType].prices.recsellPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                : null}
                                {types[this.state.selectedType].prices.showMainPrice ?
                                    Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <div className="text-size-mini text-muted no-wrap"><s>{types[this.state.selectedType].prices.mainPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                        :
                                        <div className="text-size-mini text-muted no-wrap"><s>{types[this.state.selectedType].prices.mainPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                : null}
                    {Boolean(window.portalSettings.main_price_excl_vat) ?
                        <h3 className="no-margin text-semibold"
                            style={{color: window.portalSettings.portal_pcolor}}>{types[this.state.selectedType].prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</h3>
                        :
                        <h3 className="no-margin text-semibold"
                            style={{color: window.portalSettings.portal_pcolor}}>{types[this.state.selectedType].prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</h3>
                    }
                  </span>
                :
                <span>
                            {this.props.data.prices.showRecPrice ?
                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                    <div className="text-size-mini text-muted no-wrap"><s>{this.props.data.prices.recsellPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                    :
                                    <div className="text-size-mini text-muted no-wrap"><s>{this.props.data.prices.recsellPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                :
                                null
                            }
                            {this.props.data.prices.showMainPrice ?
                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                    <div className="text-size-mini text-muted no-wrap"><s>{this.props.data.prices.mainPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                    :
                                    <div className="text-size-mini text-muted no-wrap"><s>{this.props.data.prices.mainPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                :
                                null
                            }
                    {Boolean(window.portalSettings.main_price_excl_vat) ?
                        <h3 className="no-margin text-semibold no-wrap"
                            style={{color: window.portalSettings.portal_pcolor}}>{this.props.data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</h3>
                        :
                        <h3 className="no-margin text-semibold no-wrap"
                            style={{color: window.portalSettings.portal_pcolor}}>{this.props.data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</h3>
                    }

                    </span>;
            let pricesVat = types !== undefined && types.length > 0 ?
                Boolean(window.portalSettings.main_price_excl_vat) ?
                    <div className="text-size-mini text-muted no-wrap">{types[this.state.selectedType].prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</div>
                    :
                    <div className="text-size-mini text-muted no-wrap">{types[this.state.selectedType].prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</div>
                :
                Boolean(window.portalSettings.main_price_excl_vat) ?
                    <div className="text-size-mini text-muted no-wrap">{this.props.data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</div>
                    :
                    <div className="text-size-mini text-muted no-wrap">{this.props.data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</div>
            ;

            let priceOnDemand =
                <span>
                <h3 className="no-margin text-semibold no-wrap" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("on_demand", "global")}</h3>
                <div className="text-size-mini text-muted">&nbsp;</div>
            </span>;
            let addToBasketContent =
                types !== undefined && types.length > 0 ?
                    <div className="form-group form-group-material">
                        {!types[this.state.selectedType].backorderLock ?
                        <div className="input-group">
                            <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                            <div className="input-group-btn no-padding-left">
                                <Button onClick={async () => await PT.addToBasket(this.state.amount, idParent, types[this.state.selectedType].id, this.props.data, types[this.state.selectedType])} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                            </div>
                        </div>:<div className={"unavailable-text"}>{ST.get("unavailable", "portal")}</div>}

                    </div>
                    :
                    <div className="form-group form-group-material">
                        {!backorderLock ?
                        <div className="input-group">
                            <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                            <div className="input-group-btn no-padding-left">
                                <Button onClick={async () => await PT.addToBasket(this.state.amount, idParent, 0, this.props.data)} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                            </div>
                        </div>: <div className={"unavailable-text"}>{ST.get("unavailable", "portal")}</div>}

                    </div>
            ;
            return (
                replacements.length === 0 ?
                    <tr>
                        <td className={"td-main"}>
                            <Link to={Session.getItem("basename") + "/product/" + id}><b>{name} {model}</b></Link>
                            {this.props.data.prices.customerPrices.discount > 0 ?
                                <div className="discount"><span style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>{this.props.data.prices.customerPrices.discount}%</span></div>
                                : null}
                        </td>
                        <td>
                            {types !== undefined && types.length > 0 ?
                                typeSelector
                                :
                                orderOur
                            }
                        </td>

                        <td>
                            <ProductWarehouse noLabel id={idParent} idType={(types[this.state.selectedType] !== undefined ? types[this.state.selectedType].id : 0)}/>
                        </td>

                        <td className="text-right text-semibold" style={{color: window.portalSettings.portal_pcolor}}>
                            {isLocked ?
                                <span>{priceOnDemand}</span> : <span>{prices}</span>
                            }
                        </td>
                        <td className="text-right ">
                            {isLocked ?
                                <span>{priceOnDemand}</span> : <span>{pricesVat}</span>
                            }
                        </td>
                        <td className="text-right" style={{width: "150px"}}>
                            {isLocked ?
                                null : <span>{addToBasketContent}</span>
                            }

                        </td>
                    </tr>
                    :
                    <tr>
                        <td><Link to={Session.getItem("basename") + "/product/" + id}><b>{name} {model}</b></Link></td>
                        <td>

                            orderOur

                        </td>
                        <td><Link to={Session.getItem("basename") + "/catalogue/" + catalogue.id}>{catalogue.name}</Link></td>
                        <td className="text-right text-semibold" style={{color: window.portalSettings.portal_pcolor}}> {ST.get("not_selling", "portal")}</td>
                        <td className="" colSpan={2}>
                        <span>
                                {ST.get("replacements", "product")} :&nbsp;

                            {replacements.map((element, index) => (

                                <Link key={index} className="text-semibold" to={Session.getItem("basename") + "/product/" + element.id}>
                                    {index > 0 ? ", " : ""}{element.name} {element.model}
                                </Link>

                            ))}
                            </span>
                        </td>
                    </tr>

            );
            }catch(err) {
            console.log(err);
                return null;
            }
        }


}