import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import axios from "axios";
import {ST} from "../global/SystemText";
import ProductQORow from "../components/ProductQORow";
import ProductAutocomplete from "./ProductAutocomplete";
import {BubbleLoader} from "react-css-loaders";
import readXlsxFile from 'read-excel-file'

export default class QuickOrderFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sended: false,
            showLoading: false
        }

    }

    changeInput = (event) => {
        this.setState({value: event.target.value});
    };

    selectItem(index, selected, selectedType) {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idProduct: selected,
                    idCatalogue: "0",
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    action: "GET_PORTAL_PRODUCT"
                }
            })
            .then((response) => {

                let data = this.state.data;

                data[index].product = response.data;
                data[index].idType = selectedType;
                data[index].result = 1;
                this.setState({data: data});

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    async findProduct(orderOur, amount) {
        let result = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idCurrency: Session.getItem("idCurrency"),
                idLanguage: Session.getItem("idLanguage"),
                priceLevel: Session.getItem("priceLevel"),
                orderOur: orderOur,
                amount: amount,
                action: "PORTAL_QO_FIND_PRODUCT"
            }
        });
        //console.log(result);
        let {data} = await result;
        return data;
    }

    async asyncForEach(rows, callback, data) {
        this.setState({showLoading: true})
        for (let index = 0; index < rows.length; index++) {
                let orderOur = rows[index][0];
                let amount = Number(rows[index][1]);
                let product = await callback(orderOur, amount);
                data.push(product);
        }
        return data;
    }


    async getData() {
            const input = document.getElementById('fileInput')
            await readXlsxFile(input.files[0]).then(async (rows) => {
                let data = await this.asyncForEach(rows, this.findProduct, []);

                this.setState({
                    data: data,
                    sended: true,
                    showLoading: false

                });
            })



    }

    changeOrderOur(index, event) {
        let data = this.state.data;
        data[index].product.orderOur = event.target.value;
        this.setState({data: data});
    }

    removeFromList(index) {
        let data2 = this.state.data;
        data2.splice(index, 1);
        this.setState({data: data2});
    }

    addToBasket() {
        this.state.data.forEach(async (element) => {
            if (Number(element.idType) > 0) {
                let typeIndex = 0;
                element.product.types.forEach((type, index) => {
                    if (type.id === Number(element.idType)) {
                        typeIndex = index;
                    }
                });
                await PT.addToBasket(element.amount, element.product.idParent, element.product.types[typeIndex].id, element.product, element.product.types[typeIndex], false);
            } else {
                await PT.addToBasket(element.amount, element.product.idParent, 0, element.product,null, false);

            }
        });
        this.props.hide();
    }

    changeType(index, idType){
        let data = this.state.data;
        data[index].idType=idType;

        this.setState({data})
    }
    changeAmount(index, amount){
        let data = this.state.data;
        data[index].amount=amount;

        this.setState({data})
    }


    render() {
        if (this.state.showLoading) {
            return (<div>
                    <Row>
                        <Col md={12}>
                            <BubbleLoader color={window.portalSettings.portal_pcolor}/>
                        </Col>
                    </Row>
                </div>
            );
        }
        if (this.state.sended) {
            let isOk = true;


            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <table className="table table-lg">
                                <thead>
                                <tr>
                                    <th className="text-left">{ST.get("product_name", "product")}</th>
                                    <th className="text-left">{ST.get("product_order_our", "product")}</th>
                                    <th className="text-left">{ST.get("product_availability", "product")}</th>
                                    <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                    <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                    <th className="text-right">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map((element, index) => {
                                    if (element.result === 1) {
                                        return (
                                            <ProductQORow selectedType={element.idType} data={element.product} amount={element.amount} key={"productTile" + index}/>
                                        );
                                    } else {
                                        isOk = false;
                                        return (
                                            <tr key={index}>
                                                <td><b className={"text-danger"}>{ST.get("item_not_found", "portal")}</b></td>
                                                <td>
                                                    <ProductAutocomplete onSelect={(selected, selectedType) => this.selectItem(index, selected, selectedType)} value={element.product.orderOur}
                                                                         onChangeType={(idType)=>this.changeType(index,idType)}
                                                                         onChangeAmount={(amount)=>this.changeAmount(index,amount)}
                                                    />
                                                </td>
                                                <td></td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <Button className={"pull-right btn btn-sm"} onClick={() => this.removeFromList(index)}><i className={"icon icon-bin"}/> </Button>
                                                </td>
                                            </tr>

                                        );
                                    }
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>

                        </Col>

                    </Row>
                    <div className="btn-group pull-right mt-10">
                        <Button className="btn-primary" disabled={!isOk} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => {
                            this.addToBasket()
                        }}>{ST.get("add_to_basket", "portal")}</Button>
                        <Button className="btn-default" onClick={() => this.props.hide()}>{ST.get("close", "global")}</Button>
                    </div>
                </div>)

        } else {

            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <div className={"center"}>
                                <input style={{margin:"0 auto"}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className={"file-styled-primary"} type={"file"} id={"fileInput"} onChange={async ()=>await this.getData()}/>
                            </div>
                        </Col>
                    </Row>
                    <div className="btn-group pull-right mt-10">
                        <Button className="btn-default" onClick={() => this.props.hide()}>{ST.get("close", "global")}</Button>
                    </div>
                </div>)
        }
    }
}
